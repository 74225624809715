import Card from 'common/ui/Card';
import { formatCurrency } from 'common/util/currency';
import { QuestionIcon } from '@primer/octicons-react';
import React from 'react';
import * as net from 'net';
import DividendPayoutInfo from '../../DividendPayoutInfo';
import { InvestmentDetailTransaction } from '../../investmentDetailPageTypes';

const FpmSummaries: React.FC<({
  payoutInfo: InvestmentDetailTransaction[],
  amount: number,
  originalAmount: number,
  totalDividend: number,
  totalManagementFee: number,
  totalProfit: number,
  customerTake: number,
  companyTake: number,
}
    )> = (detail) => {
      const {
        amount,
        companyTake,
        customerTake,
        originalAmount,
        totalProfit,
        totalDividend,
        totalManagementFee,
        payoutInfo,
      } = detail;
      const netProfit = Number(totalProfit) - Number(totalDividend) - Number(totalManagementFee);
      const companyRate = companyTake / 100;
      const customerRate = customerTake / 100;
      const payoutCount = payoutInfo.length;
      const dividendPerTerm = totalDividend / payoutCount;
      const managementFeePerTerm = totalManagementFee / payoutCount;
      const feesNeeded = dividendPerTerm + managementFeePerTerm;

      const getNetProfit = (startAmt: number, endAmt: number): number => {
        const differences = startAmt - endAmt;
        return differences - feesNeeded;
      };
      const getPartyTake = (amount: number, type: string): number => {
        console.log(amount);
        if (type === 'assoc') {
          if (amount < 0) {
            return 0;
          }
          return amount * customerRate;
        }
        if (amount < 0) {
          return amount;
        }
        return amount * companyRate;
      };

      const companyProfit = payoutInfo.reduce(
        (profit, current) => {
          const net = getNetProfit(current.beforeAmount, current.afterAmount);
          // console.log(net)
          const prl = getPartyTake(net, 'company');
          console.log(prl);
          return profit + prl;
        },
        0,
      );

      const customerProfit = payoutInfo.reduce(
        (profit, current) => {
          const net = getNetProfit(current.beforeAmount, current.afterAmount);
          const prl = getPartyTake(net, 'assoc');
          return profit + prl;
        },
        0,
      );

      return (
        <>
          <Card title="Investment Summaries">
            <div className="row ">
              <div className="col-3">
                <p>
                  Capital
                  <span
                    className="pl-2"
                    data-tooltip-id="formula-tooltip"
                    data-tooltip-content={`Initial Capital was: ${formatCurrency(originalAmount, 'two')}`}
                    data-tooltip-place="top"
                  >
                    <QuestionIcon />
                  </span>
                </p>
                <h5>
                  {formatCurrency(amount, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>

              <div className="col-3">
                <p>
                  Total Dividend Payout
                  <span
                    className="pl-2"
                    data-tooltip-id="formula-tooltip"
                    data-tooltip-content="Sum of all Dividend"
                    data-tooltip-place="top"
                  >
                    <QuestionIcon />
                  </span>
                </p>
                <h5>
                  {formatCurrency(totalDividend, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-3">
                <p>
                  Total Management Fee
                  <span
                    className="pl-2"
                    data-tooltip-id="formula-tooltip"
                    data-tooltip-content="Sum of all management Fee"
                    data-tooltip-place="top"
                  >
                    <QuestionIcon />
                  </span>
                </p>
                <h5>
                  {formatCurrency(totalManagementFee, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-3">
                <p>
                  Total Profit
                  <span
                    className="pl-2"
                    data-tooltip-id="formula-tooltip"
                    data-tooltip-content="Total Profit throughout the investment"
                    data-tooltip-place="top"
                  >
                    <QuestionIcon />
                  </span>
                </p>
                <h5>
                  {formatCurrency(totalProfit, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
            </div>
            <hr className="my-2" />
            <div className="row">
              <div className="col-12">
                <h5>Profits</h5>
              </div>
              <div className="col-4">
                <span className="badge badge-success py-2 px-4 font-weight-normal">
                  Net Profit
                  <span
                    className="pl-2"
                    data-tooltip-id="formula-tooltip"
                    data-tooltip-content="Total Profit - Total Dividend - Total Management Fee"
                    data-tooltip-place="top"
                  >
                    <QuestionIcon />
                  </span>
                </span>
                <h5>
                  {formatCurrency(netProfit, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-4">
                <span className="badge badge-primary py-2 px-4 font-weight-normal">
                  Associate
                  {' '}
                </span>
                <h5 className="">
                  {formatCurrency(customerProfit, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
              <div className="col-4">
                <span className="badge badge-info py-2 px-4 font-weight-normal">
                  Company
                </span>
                <h5 className="">
                  {formatCurrency(companyProfit, 'two')}
                  {' '}
                  <small>USDT</small>
                </h5>
              </div>
            </div>
          </Card>
        </>

      );
    };

export default FpmSummaries;
