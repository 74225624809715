import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import { useHistory, useParams } from 'react-router';
import Card from 'common/ui/Card';
import LabelValueRow from 'common/ui/LabelValueRow';
import { formatCurrency } from 'common/util/currency';
import { FormikDropDownList, FormikForm, FormikNumberField } from 'common/form';
import FormikDatePicker from 'common/form/FormikDatePicker';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button from 'common/ui/Button';
import { Alert, AlertType, LoadingAlert } from 'common/ui/Alert';
import { formatDateTime } from 'common/util/date';
import { ChevronRightIcon, QuestionIcon } from '@primer/octicons-react';
import { Tooltip } from 'react-tooltip';
import { InvestmentCloseType, investmentCloseTypeValues } from './investmentCloseType';
import { EguyCloseSummaryReduxPageState } from './closeInvestmentPageTypes';
import { eguyCloseSummaryActions } from './eguyCloseInvestmentSlice';
import { investmentTransactionTypeLookup } from '../shared/investmentTransactionType';
import SettlementInfo from '../detail/component/SettlementInfo';

const CloseInvestmentPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: EguyCloseSummaryReduxPageState) => s.eguySummary.pageLoading,
  );
  const detail = useSelector(
    (s: EguyCloseSummaryReduxPageState) => s.eguySummary.detail,
  );

  const formLoading = useSelector(
    (s: EguyCloseSummaryReduxPageState) => s.eguySummary.formLoading,
  );

  const companyRate = detail.companyTake / 100;
  const latestProfit = detail.finalAmount - detail.amount;
  const companyFinalTake = latestProfit * companyRate;
  let finalPayable = 0;
  switch (detail.fundType) {
    case 'cpm':
      finalPayable = detail.finalAmount - companyFinalTake;
      break;
    case 'tpm':
      finalPayable = detail.finalAmount - companyFinalTake;
      break;
    case 'fpm':
      finalPayable = detail.amount;
      break;
    case 'eps':
      finalPayable = detail.amount * 1.1;
      break;
    default:
      finalPayable = 0;
      break;
  }
  const epsManagementFee = detail.amount * 0.02;
  let epsStaffPayable = detail.finalAmount - finalPayable - epsManagementFee;
  if (epsStaffPayable < 0) {
    epsStaffPayable = 0;
  }

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const idNumber = parseInt(id, 10);
    dispatch(eguyCloseSummaryActions.init(idNumber));
  }, []);

  return (
    <ContentContainer>
      <PageTitle onBack={() => history.goBack()}>
        Close Investment
      </PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <Card
          title={`Summary for ${detail.formNo}`}
        >
          <div className="row">
            <div className="col-md-6">
              <LabelValueRow label="Start Date">
                {detail.dateTime}
              </LabelValueRow>
            </div>
            <div className="col-md-6">
              <LabelValueRow label="Customer">
                {detail.customerName}
              </LabelValueRow>
            </div>
            <div className="col-12">
              <LabelValueRow label="Amount">
                {`${formatCurrency(detail.amount, 'two')} USDT`}
              </LabelValueRow>
            </div>
          </div>
        </Card>

        <div className="my-4" />
        {
          detail.fundType !== 'eps' && (
          <Card
            title="Last Payable to Customer"
          >
            <div className="row">
              {
                (detail.fundType === 'cpm' || detail.fundType === 'tpm') && (
                <>
                  <div className="col-4">
                    <p>Closing Balance</p>
                    <h5>
                      {formatCurrency(detail.finalAmount, 'two')}
                      {' '}
                      <small>USDT</small>
                    </h5>
                  </div>
                  <div className="col-4">
                    <p>Closing Profit</p>
                    <h5>
                      {formatCurrency(latestProfit, 'two')}
                      {' '}
                      <small>USDT</small>
                    </h5>
                  </div>
                  <div className="col-4">
                    <p>
                      Closing Profit Company Share (
                      {detail.companyTake}
                      {' '}
                      %)
                      <span
                        className="pl-2"
                        data-tooltip-id="info-tooltips"
                        data-tooltip-content="Share of Closing Profit"
                        data-tooltip-place="top"
                      >
                        <QuestionIcon />
                      </span>
                    </p>
                    <h5>
                      {formatCurrency(companyFinalTake, 'two')}
                      {' '}
                      <small>USDT</small>
                    </h5>
                  </div>
                </>
                )
                    }
              <div className={detail.fundType === 'eps' ? 'col-6 mt-4' : 'col-12 mt-4'}>
                <Alert type={AlertType.success}>
                  <div className="text-center py-2">
                    {
                              detail.fundType === 'fpm' && (
                              <small>
                                This fund do not have final profit calculation because it is FPM,
                                hence invested amount is paid back to customer
                              </small>
                              )
                          }
                    <p>
                      Final Payable to Customer
                      <span
                        className="pl-2"
                        data-tooltip-id="info-tooltips"
                        data-tooltip-content="Formula: Initial Amount * 1.1"
                        data-tooltip-place="top"
                      >
                        <QuestionIcon />
                      </span>
                    </p>
                    <h5 className="text-dark">
                      {formatCurrency(finalPayable, 'two')}
                      {' '}
                      <small>USDT</small>
                    </h5>
                    <small>
                      Please be noted this will be the final amount needed to pay investor.
                      If the figure is incorrect, please find the developer in charge
                    </small>
                  </div>
                </Alert>
              </div>
              {
                        detail.fundType === 'eps' && (
                        <div className="col-6">
                          <Alert type={AlertType.success}>
                            <div className="text-center py-2">
                              <p>
                                Final Payable to Staffs
                                <span
                                  className="pl-2"
                                  data-tooltip-id="info-tooltips"
                                  data-tooltip-content="Formula: (Closing Balance - 2% Capital) - Payable to Customer"
                                  data-tooltip-place="top"
                                >
                                  <QuestionIcon />
                                </span>
                              </p>
                              {' '}
                              <h5 className="text-dark">
                                {formatCurrency(epsStaffPayable, 'two')}
                                {' '}
                                <small>USDT</small>
                              </h5>
                              <small>
                                Please be noted this will be the final amount needed
                                to pay all staffs.
                                for more details please refer to CRM
                              </small>
                            </div>
                          </Alert>
                        </div>
                        )
                    }
            </div>
            {/* <table className="table table-bordered"> */}
            {/* <thead> */}
            {/*  <tr> */}
            {/*    <th>Description</th> */}
            {/*    <th>Amount</th> */}
            {/*  </tr> */}
            {/*  </thead> */}
            {/*  <tbody> */}
            {/*  <tr> */}
            {/*    <td>Closing Balance</td> */}
            {/*    <td>{`$ USDT`}</td> */}
            {/*  </tr> */}
            {/*  <tr> */}
            {/*    <td>Profit Taken By Company</td> */}
            {/*    <td> */}
            {/*      ( */}
            {/*      {`${formatCurrency(companyFinalTake, 'two')} USDT`} */}
            {/*      ) */}
            {/*    </td> */}
            {/*  </tr> */}
            {/*  </tbody> */}
            {/*  <tfoot> */}
            {/*  <tr className="font-weight-bold"> */}
            {/*    <td>Final Payable Amount</td> */}
            {/*    <td> */}
            {/*      {`${formatCurrency(finalPayable, 'two')} USDT`} */}
            {/*    </td> */}
            {/*  </tr> */}
            {/*  </tfoot> */}
            {/* </table> */}
          </Card>
          )
        }

        <div className="my-3" />
        <SettlementInfo detail={
            {
              companyTake: detail.companyTake,
              customerTake: detail.customerTake,
              transactions: detail.portfolioTransactions,
              totalPartialPayout: detail.totalPayout,
              amount: detail.amount,
              originalAmount: detail.originalAmount,
              currentAmount: detail.finalAmount,
              fundType: detail.fundType,
              totalDividend: detail.totalDividend,
              totalManagementFee: detail.totalManagementFee,
            }
          }
        />
        <div className="my-4" />
        <Card title="Transaction History">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date Time</th>
                <th>Reason</th>
                <th>Before Amount</th>
                <th>Amount</th>
                <th>After Amount</th>
              </tr>
            </thead>
            <tbody>
              {detail.portfolioTransactions.map((i) => (
                <tr key={i.id} className={i.amount > 0 ? 'text-success' : 'text-danger'}>
                  <td>{formatDateTime(i.dateTime)}</td>
                  <td>{investmentTransactionTypeLookup[i.type]?.label}</td>
                  <td>{formatCurrency(i.beforeAmount, 'two')}</td>
                  <td>
                    {formatCurrency(i.amount, 'two')}
                    {i.amount >= 0 && (
                      <small>
                        {' '}
                        (+
                        {formatCurrency(i.percentage, 'two')}
                        %)
                      </small>
                    )}
                    {i.amount < 0 && (
                      <small>
                        {' '}
                        (
                        {formatCurrency(i.percentage, 'two')}
                        %)
                      </small>
                    )}
                    {i.companyLost > 0 && (
                      <div className="text-muted">
                        <ChevronRightIcon />
                        <small className="font-italic">{formatCurrency(i.companyLost, 'two')}</small>
                      </div>
                    )}
                  </td>
                  <td>{formatCurrency(i.afterAmount, 'two')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        <Tooltip id="info-tooltips" />
        <div className="my-3" />
        <FormikForm
          initialValues={{
            id: detail.id,
            investorPayable: finalPayable,
            type: InvestmentCloseType.Withdraw,
            reinvestAmount: 0,
            effectiveDate: null,
          }}
              // validate={validateCloseInvestmentForm}
          onSubmit={(form) => {
            dispatch(eguyCloseSummaryActions.submit(form));
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Card title="Closing Form">
                <LoadingAlert loading={formLoading} />
                <div className="row">
                  <div className="col-12">
                    <FormikDatePicker
                      name={nameof(values.effectiveDate)}
                      label="Effective Date"
                    />
                  </div>
                  {
                        detail.fundType !== 'eps' && (
                        <div className="col-12">
                          <FormikDropDownList
                            name={nameof(values.type)}
                            label="Investor Will"
                            values={investmentCloseTypeValues}
                            onChange={(value) => {
                              if (value === InvestmentCloseType.Withdraw) {
                                setFieldValue(nameof(values.reinvestAmount), 0);
                              }
                            }}
                          />
                        </div>
                        )
                      }
                  {values.type === InvestmentCloseType.Reinvest && (
                  <div className="col-12">
                    <FormikNumberField
                      name={nameof(values.reinvestAmount)}
                      label="Reinvest Amount"
                      decimal={2}
                    />
                  </div>
                  )}
                </div>
              </Card>

              <FooterActionContainer>
                <Button
                  type="submit"
                  label="Close Investment"
                />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CloseInvestmentPage;
