import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal, { ModalPromptButton } from 'common/ui/Modal';
import { FormikDatePicker, FormikForm, FormikNumberField } from 'common/form';
import FormValue from 'common/ui/FormValue';
import { formatCurrency } from 'common/util/currency';
import {
  dateValidator, numberValidator, stringValidator, Validate,
} from 'common/form/validations';
import Big from 'big.js';
import { LoadingAlert } from 'common/ui/Alert';
import { QuestionIcon } from '@primer/octicons-react';
import { DateTime } from 'luxon';
import { investmentDetailActions } from './investmentDetailPageSlice';
import { InvestmentDetailPageReduxState, InvestmentPartialPayoutForm } from './investmentDetailPageTypes';

const initialForm: InvestmentPartialPayoutForm = {
  id: 0,
  amount: null,
  percent: null,
  transactionDateTime: null,
  customerAmount: null,
  companyAmount: null,
};

const PartialPayoutModal: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialWithdrawalLoading,
  );
  const prompt = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.partialWithdrawalPrompt,
  );
  const fullData = useSelector(
    (s: InvestmentDetailPageReduxState) => s.investmentDetail.detail,
  );

  const payableAmount = fullData.amount;
  const [promptForm, setPromptForm] = React.useState(initialForm);
  const validateForm: Validate<InvestmentPartialPayoutForm> = ({ form, validator }) => {
    validator
      .validateField(nameof(form.amount),
        stringValidator.required(),
        numberValidator.gt(0))
      .validateField(nameof(form.transactionDateTime), dateValidator.required());

    return validator;
  };

  React.useEffect(() => {
    if (loading.isSuccess) {
      dispatch(investmentDetailActions.setPartialWithdrawalPrompt());
    }
  }, [loading]);

  const calculateAmount = (percent: number) => new Big(payableAmount)
    .mul(percent)
    .div(100)
    .round(3)
    .toNumber();

  React.useEffect(() => {
    if (prompt.id) {
      const defaultPercent = 100;
      setPromptForm({
        ...promptForm,
        id: prompt.id,
        amount: calculateAmount(defaultPercent),
        // transactionDateTime: null,
      });
    }
  }, [prompt]);

  React.useEffect(() => {
    // console.log(payableAmount)
    setPromptForm(
      {
        ...promptForm,
        amount: payableAmount,
      },
    );
  }, [payableAmount]);

  return (
    <Modal
      size="small"
      isOpen={prompt.id > 0 && prompt.isWithdraw}
    >
      <h3>Perform Withdrawal</h3>
      <FormikForm
        initialValues={promptForm}
        enableReinitialize
        validate={validateForm}
        onSubmit={(f) => {
          dispatch(investmentDetailActions.submitPartialWithdrawal(f));
          setPromptForm({
            ...promptForm,
            transactionDateTime: null,
          });
        }}
      >
        {({
          values, handleSubmit, setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <LoadingAlert loading={loading} />
            <FormValue
              label="Form No:"
              value={prompt.name}
            />
            <div className="alert alert-success text-center">
              <p>
                Total Payable Amount
                <span
                  className="pl-2"
                  data-tooltip-id="formula-tooltip"
                  data-tooltip-content="Formula: Current Pool Amount - Original Amount"
                  data-tooltip-place="top"
                >
                  <QuestionIcon />
                </span>
              </p>
              <h4 className="">{`${formatCurrency(payableAmount, 'three')} USDT`}</h4>
            </div>
            <FormikDatePicker
              time
              name={nameof(values.transactionDateTime)}
              label="Transaction Date Time"
              onChange={(selectedDate) => {
                setPromptForm({
                  ...promptForm,
                  transactionDateTime: selectedDate,
                });
              }}
            />
            <FormikNumberField
              name={nameof(values.amount)}
              decimal={3}
              inputPostLabel="USDT"
              label="Payout Amount"
            />
            <ModalPromptButton
              isLoading={loading.isLoading}
              yesLabel="Confirm"
              noLabel="Cancel"
              onYesClick={handleSubmit}
              onNoClick={() => dispatch(investmentDetailActions.closeWithdrawalForm())}
            />
          </form>
        )}
      </FormikForm>
    </Modal>
  );
};

export default PartialPayoutModal;
