import { ValueLabelLookup } from 'common/util/lookup';
import { keyBy } from 'lodash';

export enum InvestmentTransactionType {
  Deposit = 'deposit',
  ReferralFee = 'referral_fee',
  ToSpotFund = 'to_spot_funds',
  ToFutureFund = 'to_future_funds',
  FromSpotFund = 'from_spot_funds',
  FromFutureFund = 'from_future_funds',
  PartialPayout = 'partial_payout',
  PartialWithdrawal = 'partial_withdrawal',
  Settlements = 'settlements',
  ManagementFees = 'management_fee',
}

export const investmentTransactionTypeValues: ValueLabelLookup[] = [
  { label: 'Deposit', value: InvestmentTransactionType.Deposit },
  { label: 'Referral Fee', value: InvestmentTransactionType.ReferralFee },
  { label: 'To Spot Account', value: InvestmentTransactionType.ToSpotFund },
  { label: 'To Future Account', value: InvestmentTransactionType.ToFutureFund },
  { label: 'From Spot Account', value: InvestmentTransactionType.FromSpotFund },
  { label: 'From Future Account', value: InvestmentTransactionType.FromFutureFund },
  { label: 'Partial Payout', value: InvestmentTransactionType.PartialPayout },
  { label: 'Partial Withdrawal', value: InvestmentTransactionType.PartialWithdrawal },
  { label: 'Investment Settlements', value: InvestmentTransactionType.Settlements },
  { label: 'Management Fees', value: InvestmentTransactionType.ManagementFees },
];

export const investmentTransactionTypeLookup = keyBy(investmentTransactionTypeValues, 'value');
