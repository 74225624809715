import { Validate, stringValidator, dateValidator } from 'common/form/validations';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { EditInvestmentForm } from './editInvestmentPageTypes';

const validateEditInvestmentForm: Validate<EditInvestmentForm> = ({ validator, form }) => {
  validator
    .validateField(
      nameof.full(form.customerId, 1),
      stringValidator.required(),
    );
  return validator;
};

export default validateEditInvestmentForm;
