import { fromApiDate } from 'common/util/date';
import { SettlementsInfo, Settlement, SettlementInfoApi } from './settlementPageTypes';

// eslint-disable-next-line import/prefer-default-export
export const mapFutureTransactionsFromApi = (response: SettlementInfoApi): SettlementsInfo => {
  const { list: settlements } = response;
  const { summary } = response;
  const pnlSum = settlements.reduce(
    (sum, r) => {
      const currentPnlPercentage = r.pnl_percent * 100;
      const rounded_num = Math.round(currentPnlPercentage) / 100;
      return sum + rounded_num;
    }, 0,
  ); // 0 is the initial value of the accumulator

  console.log(pnlSum);

  const mapped = settlements.map<Settlement>((b) => ({
    id: b.id,
    currentAmount: b.before_amount,
    dateTime: fromApiDate(b.settlement_date),
    amount: b.amount,
    afterAmount: b.after_amount,
    percentage: b.pnl_percent,
    totalPax: b.invovled_pax,
  }));

  return {
    currentFundPool: summary.current_total,
    originalFundPool: summary.original_total,
    pnl: pnlSum,
    // currentFundPool: response.fundPool,
    activeInvestors: summary.total_pax,
    settlements: mapped,
  };
};
