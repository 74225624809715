import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import emptyFunction from 'common/util/emptyFunction';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { DateTime } from 'luxon';
import { isUndefined } from 'lodash';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { InvestmentStatus } from '../shared/investmentStatus';
import { ReferralDjMode } from '../shared/referralDjMode';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import {
  CalculatePayoutInfoNeeded, CalculatePayoutInfoNeededFPM,
  InvestmentDetail, InvestmentDetailPageState,
  InvestmentDetailSpotBalance, InvestmentPartialPayoutForm, InvestmentPartialPayoutPrompt,
  InvestmentPendingSettlementPrompt,
} from './investmentDetailPageTypes';

const initialPendingSettlementPrompt: InvestmentPendingSettlementPrompt = {
  id: 0,
  name: '',
};

const initialPartialPaymentPrompt: InvestmentPartialPayoutPrompt = {
  id: 0,
  name: '',
  amount: 0,
  originalAmount: 0,
  fundType: '',
  isWithdraw: false,
};

const initPartialWithdrawalPrompt : InvestmentPartialPayoutPrompt = {
  id: 0,
  name: '',
  amount: 0,
  originalAmount: 0,
  fundType: '',
  isWithdraw: true,
};

const initialState: InvestmentDetailPageState = {
  pageLoading: initialLoadingTrueState,
  detail: {
    id: 0,
    customerId: 0,
    customer: {
      address: '',
      contactNumber: '',
      idNumber: '',
      name: '',
    },
    formNo: '',
    transactionDateTime: '',
    amount: 0,
    status: InvestmentStatus.Active,
    bonusPayoutRate: 0,
    referralName: '',
    referralFee: 0,
    companyTake: 0,
    customerTake: 0,
    originalAmount: 0,
    currentAmount: 0,
    referralTransactionDateTime: '',
    loginPassword: '',
    totalPartialPayout: 0,
    transactions: [],
    childInvestments: [],
    fundType: '',
    annualPayoutPerc: 0,
    mgmtFeePerc: 0,
  },
  spotBalance: {
    unrealizedProfitLoss: 0,
    totalMarketValue: 0,
    data: [],
  },
  pendingSettlementLoading: initialLoadingState,
  pendingSettlementPrompt: initialPendingSettlementPrompt,
  partialPaymentPrompt: initialPartialPaymentPrompt,
  partialPaymentLoading: initialLoadingState,
  partialWithdrawalLoading: initialLoadingState,
  partialWithdrawalPrompt: initPartialWithdrawalPrompt,
  partialPayoutAmount: 0,
  profitAvailable: 0,

};

const investmentDetailSlice = createSlice({
  name: 'investmentDetail',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _state: PayloadAction<number>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setDetail(state, { payload }: PayloadAction<InvestmentDetail>) {
      state.detail = payload;
      return state;
    },
    setSpotBalance(state, { payload }: PayloadAction<InvestmentDetailSpotBalance>) {
      state.spotBalance = payload;
      return state;
    },
    setPendingSettlementLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pendingSettlementLoading = payload;
      return state;
    },
    setPendingSettlementPrompt(state, { payload }:
      PayloadAction<InvestmentPendingSettlementPrompt|undefined>) {
      state.pendingSettlementPrompt = payload || initialPendingSettlementPrompt;
      return state;
    },
    confirmPendingSettlement: emptyFunction,
    setPartialPaymentPrompt(state, { payload }:
      PayloadAction<InvestmentPartialPayoutPrompt|undefined>) {
      state.partialPaymentPrompt = payload || initialPartialPaymentPrompt;
      if (!isUndefined(payload)) {
        console.log(payload.fundType);
      }
      return state;
    },
    setPartialWithdrawalPrompt(state, { payload }:
        PayloadAction<InvestmentPartialPayoutPrompt|undefined>) {
      state.partialWithdrawalPrompt = payload || initialPartialPaymentPrompt;
      if (!isUndefined(payload)) {
        console.log(payload.fundType);
      }
      return state;
    },
    submitPartialPayment: (state, _payload: PayloadAction<InvestmentPartialPayoutForm>) => state,
    submitPartialWithdrawal: (state, _payload: PayloadAction<InvestmentPartialPayoutForm>) => state,
    setPartialPaymentLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.partialPaymentLoading = payload;
      return state;
    },
    setPartialWithdrawalLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.partialWithdrawalLoading = payload;
      return state;
    },
    setAvailablePayout(state, { payload } : PayloadAction<number>) {
      state.partialPayoutAmount = payload;
      return state;
    },
    setAvailablePayoutByDate(state, { payload } : PayloadAction<CalculatePayoutInfoNeeded>) {
      // Need to get State and get state:
      if (payload.selectedDate === null) {
        state.partialPayoutAmount = payload.transactions
          .filter((item) => item.type === 'settlements' || item.type === 'partial_payout')
          .reduce(
            (totalProfit, currentProfit) => currentProfit.amount + totalProfit,
            0,
          );
      } else {
        state.partialPayoutAmount = payload.transactions
          .filter((i) => i.dateTime.diff(payload.selectedDate ?? new DateTime()).milliseconds < 0
              && (i.type === 'settlements' || i.type === 'partial_payout'))
          .reduce(
            (totalProfit, currentProfit) => currentProfit.amount + totalProfit,
            0,
          );
      }
      return state;
    },

    setFPMAvailablePayoutByDate(state, { payload } : PayloadAction<CalculatePayoutInfoNeededFPM>) {
      let amount = 0;
      let profitAvailable = 0;
      if (payload.selectedDate === null) {
        amount = state.detail.currentAmount - state.detail.originalAmount;
      } else {
        amount = payload.transactions
          .filter((i) => i.dateTime.diff(payload.selectedDate ?? new DateTime()).milliseconds < 0
                && (i.type === 'settlements' || i.type === 'partial_payout'))
          .reduce(
            (totalProfit, currentProfit) => {
              if (currentProfit.beforeAmount < currentProfit.afterAmount) {
                return currentProfit.amount + totalProfit;
              }
              const differences = currentProfit.beforeAmount - currentProfit.afterAmount;
              return totalProfit - differences;
            },
            0,
          );
      }
      if (amount > 0) {
        profitAvailable = amount;
        if (amount <= payload.dividendNeeded) {
          amount = payload.dividendNeeded;
        }
      } else {
        profitAvailable = 0;
        amount = payload.dividendNeeded;
      }
      state.partialPayoutAmount = amount;
      state.profitAvailable = profitAvailable;
      return state;
    },
    setNetProfit(state, { payload } : PayloadAction<number>) {
      state.profitAvailable = payload;
      return state;
    },
    closeWithdrawalForm(state, { payload }:
        PayloadAction<InvestmentPendingSettlementPrompt|undefined>) {
      state.partialWithdrawalPrompt = initPartialWithdrawalPrompt;
      return state;
    },
  },
});

export const investmentDetailActions = investmentDetailSlice.actions;
export const investmentDetailReducer = investmentDetailSlice.reducer;
