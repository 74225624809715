import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingSwitch from 'common/ui/LoadingSwitch';

import ContentContainer from 'common/ui/ContentContainer';
import PageTitle from 'common/ui/PageTitle';
import Card from 'common/ui/Card';
import { formatCurrency } from 'common/util/currency';
import { Link } from 'react-router-dom';
import { PageScrollDirection } from 'common/util/list';
import Pagination from 'common/ui/Pagination';
import { KebabHorizontalIcon } from '@primer/octicons-react';
import { IconButton } from 'common/ui/Button';
import emptyFunction from 'common/util/emptyFunction';
import classes from './investmentListPage.module.scss';
import { investmentListActions } from './investmentListPageSlice';
import DeleteInvestmentModal from './DeleteInvestmentModal';
import { InvestmentListPageReduxState } from './investmentListPageTypes';
import {
  investmentColourLookup,
  investmentStatusLookup,
} from '../shared/investmentStatus';

const InvestmentListPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: InvestmentListPageReduxState) => s.investmentList.pageLoading,
  );
  const search = useSelector(
    (s: InvestmentListPageReduxState) => s.investmentList.search,
  );
  const list = useSelector(
    (s: InvestmentListPageReduxState) => s.investmentList.list,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    dispatch(investmentListActions.init());
  }, []);

  const roundAmt = (number: number) => {
    const large_num = number * 1000;
    const rounded_num = Math.round(large_num);
    return rounded_num / 1000;
  };

  const sumOfCurrentPage = list.data.reduce((total, item) => item.amount + total, 0);
  const profitSumOfCurrentPage = list.data.reduce((pTotal, item) => {
    const num = roundAmt(item.currentAmount);
    return num + pTotal;
  }, 0);
  const totalProfitAmount = profitSumOfCurrentPage - sumOfCurrentPage;
  const profitPercentage = totalProfitAmount / sumOfCurrentPage;

  return (
    <ContentContainer>
      <PageTitle>Investment List</PageTitle>
      <Card>
        <input
          type="search"
          className="form-control mb-3"
          placeholder="Search by customer name or form no."
          value={search}
          onChange={(e) => dispatch(investmentListActions.search(e.target.value))}
        />
        <LoadingSwitch loading={pageLoading}>
          <table className="table table-hover table-responsive-md">
            <thead>
              <tr>
                <th scope="col">Form No.</th>
                <th scope="col">Type</th>
                <th scope="col">Customer</th>
                <th scope="col">Invested Amount (USDT)</th>
                <th scope="col">Current Amount (USDT)</th>
                <th scope="col">Investment Date</th>
                <th scope="col">Status</th>
                <th scope="col" aria-label="Actions" />
              </tr>
            </thead>
            <tbody>
              {list.data.map((i) => (
                <tr key={i.id} className={investmentColourLookup[i.status].label}>
                  <th scope="row">
                    <Link to={`/member/investment/detail/${i.id}`}>
                      {i.formNo}
                    </Link>
                  </th>
                  {/* <td>{i.investmentType}</td> */}
                  <td>{i.fundType.toUpperCase()}</td>
                  <td>{i.customerName}</td>
                  <td>{formatCurrency(i.amount, 'two')}</td>
                  <td>
                    {formatCurrency(i.currentAmount, 'two')}
                    <br />
                    <small className={`text-sm italic ${i.profit > 0 ? 'text-success' : 'text-danger'}`}>
                      {formatCurrency(i.profit, 'two')}
                      {' '}
                      (
                      {formatCurrency(i.profitPercentage, 'two')}
                      %)
                    </small>
                  </td>
                  <td>{i.maturityDate}</td>
                  <td>{investmentStatusLookup[i.status].label}</td>
                  <td className="align-middle">
                    <IconButton
                      icon={KebabHorizontalIcon}
                      onClick={emptyFunction}
                      id={`action-${i.id}`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />

                    <div className="dropdown-menu" aria-labelledby={`action-${i.id}`}>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => dispatch(investmentListActions.setDeletePrompt({
                          id: i.id,
                          name: i.formNo,
                        }))}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td />
                <td>
                  {
                    formatCurrency(sumOfCurrentPage, 'two')
                  }
                </td>
                <td>
                  {
                    formatCurrency(profitSumOfCurrentPage, 'two')
                  }
                  <br />
                  <small className="italic">
                    {formatCurrency(totalProfitAmount, 'two')}
                    {' '}
                    (
                    {formatCurrency(profitPercentage * 100, 'two')}
                    %)

                  </small>
                </td>
                <td />
                <td />
                <td />
              </tr>
            </tfoot>
          </table>
          <Pagination
            hasNextPage={list.info.hasNextPage}
            hasPrevPage={list.info.hasPreviousPage}
            onNextPageClick={() => dispatch(investmentListActions.page(PageScrollDirection.Next))}
            onPrevPageClick={() => dispatch(
              investmentListActions.page(PageScrollDirection.Previous),
            )}
          />
        </LoadingSwitch>
      </Card>
      <DeleteInvestmentModal />
    </ContentContainer>
  );
};

export default InvestmentListPage;
