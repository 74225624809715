import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Big } from 'big.js';

import LoadingSwitch from 'common/ui/LoadingSwitch';
import PageTitle from 'common/ui/PageTitle';

import {
  FormikDatePicker, FormikDropDownList, FormikForm, FormikNumberField, FormikTextField,
} from 'common/form';
import Card from 'common/ui/Card';
import ContentContainer from 'common/ui/ContentContainer';
import FooterActionContainer from 'common/ui/FooterActionContainer';
import Button, { LinkButton } from 'common/ui/Button';
import { LoadingAlert } from 'common/ui/Alert';
import appConfig, { isUseV2Calculation } from 'common/setup/config';
import { createInvestmentActions } from './createInvestmentPageSlice';
import { CreateInvestmentPageQueryString, CreateInvestmentPageReduxState } from './createInvestmentPageTypes';
import validateCreateInvestmentForm from './createInvestmentPageValidation';
import { investmentDetailActions } from '../detail/investmentDetailPageSlice';

const getBonusPercentage = (amount: number | null) => {
  if (appConfig.appVersion >= 2) {
    return 0;
  }

  if (!amount) {
    return 0;
  }
  if (amount >= 2500000) {
    return 25;
  }
  if (amount >= 1000000) {
    return 15;
  }
  if (amount >= 500000) {
    return 10;
  }
  if (amount >= 100000) {
    return 8;
  }
  if (amount >= 50000) {
    return 5;
  }

  return 0;
};

const CreateInvestmentPage: React.FC = () => {
  const dispatch = useDispatch();

  // Normally when user visits the page, has to load some data from remote
  // for showing, the `pageLoading` state is used to keep track this.
  const pageLoading = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.pageLoading,
  );
  const formLoading = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.formLoading,
  );
  const form = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.form,
  );
  const customers = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.customers,
  );
  const fundTypes = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.fundTypes,
  ).map((item) => ({
    label: item.toUpperCase(),
    value: item.toLowerCase(),
  }));

  const profitLabel = useSelector(
    (s: CreateInvestmentPageReduxState) => s.createInvestment.profitLabel,
  );

  // When user visits the page, dispatch an action immediately to inform
  // saga to do page initialisation (mainly to load data needed).
  React.useEffect(() => {
    const qs = queryString.parse(window.location.search);
    const initParam: CreateInvestmentPageQueryString = {
      createdCustomer: typeof qs.createdCustomer === 'string' ? Number(qs.createdCustomer) : undefined,
    };

    dispatch(createInvestmentActions.init(initParam));
  }, []);

  const onSubmit = React.useCallback((f) => {
    dispatch(createInvestmentActions.submit(f));
  }, [dispatch]);

  return (
    <ContentContainer>
      <PageTitle>Add Investment</PageTitle>
      <LoadingSwitch loading={pageLoading}>
        <FormikForm
          initialValues={form}
          validate={validateCreateInvestmentForm}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <LoadingAlert loading={formLoading} />
              <Card title="Customer">
                <FormikDropDownList
                  name={nameof(values.customerId)}
                  label="Select Customer"
                  values={customers}
                />
                <LinkButton
                  label="Create New Customer"
                  onClick={() => {
                    dispatch(createInvestmentActions.createNewCustomer(values));
                  }}
                />
              </Card>
              <div className="my-3" />
              <Card title="Investment">
                <div className="row">
                  <div className="col-4">
                    <FormikDropDownList
                      id={nameof(values.fundType)}
                      name={nameof(values.fundType)}
                      values={fundTypes}
                      label="Fund Type"
                      onChange={(newValue) => {
                        if (newValue === 'fpm') {
                          setFieldValue(nameof(values.annualPayoutPerc), 10);
                          setFieldValue(nameof(values.mgmtFeePerc), 2);
                        }
                        if (newValue === 'cpm') {
                          setFieldValue(nameof(values.customerTake), 10);
                          setFieldValue(nameof(values.companyTake), 0);
                        }
                        dispatch(createInvestmentActions.setProfitLabel(newValue ?? ''));
                      }}
                    />
                  </div>
                  <div className="col-8">
                    <FormikTextField
                      id={nameof(values.formNo)}
                      name={nameof(values.formNo)}
                      label="Form No."
                    />
                  </div>
                  {
                          values.fundType === 'fpm' && (
                          <div className="col-6">
                            <FormikNumberField
                              id={nameof(values.annualPayoutPerc)}
                              name={nameof(values.annualPayoutPerc)}
                              inputPostLabel="%"
                              label="Annual Payout"
                            />
                          </div>
                          )
                      }
                  {
                          values.fundType !== 'eps' && (
                          <div className="col-6">
                            <FormikNumberField
                              id={nameof(values.mgmtFeePerc)}
                              name={nameof(values.mgmtFeePerc)}
                              inputPostLabel="%"
                              label="Annual Management Fees "
                            />
                          </div>
                          )
                      }

                  <div className="col-12 col-md-6">
                    <FormikDatePicker
                      id={nameof(values.transactionDateTime)}
                      name={nameof(values.transactionDateTime)}
                      label="Transaction Date & Time"
                      time
                      onChange={(date) => {
                        if (date) {
                          const plusYear = isUseV2Calculation() ? 3 : 1;
                          setFieldValue(
                            nameof(values.maturityDate),
                            date.plus({ years: plusYear }),
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <FormikNumberField
                      id={nameof(values.amount)}
                      name={nameof(values.amount)}
                      label="Amount"
                      inputPreLabel="USDT"
                      decimal={2}
                    />
                  </div>
                </div>
              </Card>
              <div className="my-3" />
              {
                      values.fundType !== 'eps' && (
                      <>
                        <Card title="Profit Sharing">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <FormikNumberField
                                id={nameof(values.companyTake)}
                                name={nameof(values.companyTake)}
                                label="Company Profit Take"
                                inputPostLabel="%"
                                onBlur={(value) => {
                                  if (value) {
                                    setFieldValue(nameof(form.customerTake), 100 - value);
                                  }
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-6">
                              <FormikNumberField
                                id={nameof(values.customerTake)}
                                name={nameof(values.customerTake)}
                                label={profitLabel}
                                inputPostLabel="%"
                              />
                            </div>
                          </div>
                        </Card>
                      </>
                      )
                  }

              <div className="my-3" />
              <FooterActionContainer>
                <Button type="submit" label="Save" isLoading={pageLoading.isLoading} />
              </FooterActionContainer>
            </form>
          )}
        </FormikForm>
      </LoadingSwitch>
    </ContentContainer>
  );
};

export default CreateInvestmentPage;
