import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialLoadingState, initialLoadingTrueState, LoadingData } from 'common/util/loading';
import { IdLabelLookup } from 'common/util/lookup';
import { ExchangeNetwork } from '../shared/exchangeNetwork';
import { ReferralDjMode } from '../shared/referralDjMode';
import { ReferralPayoutMode } from '../shared/referralPayoutMode';
import { CreateInvestmentForm, CreateInvestmentPageQueryString, CreateInvestmentPageState } from './createInvestmentPageTypes';

export const initialInvestmentForm: CreateInvestmentForm = {
  customerId: null,
  formNo: '',
  transactionDateTime: null,
  maturityDate: null,
  amount: null,
  bonusPayoutRate: 0,
  exchangeNetwork: ExchangeNetwork.ERC20,
  walletAddress: '',
  transactionHash: '',
  referralName: '',
  referralPayoutMode: ReferralPayoutMode.Yearly,
  referralDjMode: ReferralDjMode.None,
  referralFee: null,
  referralDjFee: null,
  referralTransactionDateTime: null,
  referralExchangeNetwork: ExchangeNetwork.ERC20,
  referralWalletAddress: '',
  referralTransactionHash: '',
  customerTake: 70,
  companyTake: 30,
  annualPayoutPerc: 0,
  mgmtFeePerc: 0,
  fundType: '',
};

const initialState: CreateInvestmentPageState = {
  pageLoading: initialLoadingTrueState,
  formLoading: initialLoadingState,
  formCache: null,
  form: initialInvestmentForm,
  customers: [],
  fundTypes: ['FPM', 'CPM', 'TPM'],
  profitLabel: 'Customer Profit Take',
};

const createInvestmentSlice = createSlice({
  name: 'createInvestment',
  initialState,
  reducers: {
    setInitialState() {
      return initialState;
    },
    init: (state, _action: PayloadAction<CreateInvestmentPageQueryString>) => state,
    createNewCustomer: (state, _action: PayloadAction<CreateInvestmentForm>) => state,
    setPageLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.pageLoading = payload;
      return state;
    },
    setFormLoading(state, { payload }: PayloadAction<LoadingData>) {
      state.formLoading = payload;
      return state;
    },
    setCustomers(state, { payload }: PayloadAction<IdLabelLookup[]>) {
      state.customers = payload;
      return state;
    },
    setForm(state, { payload }: PayloadAction<CreateInvestmentForm>) {
      state.form = payload;
      return state;
    },
    setFormCache(state, { payload }: PayloadAction<CreateInvestmentForm|null>) {
      state.formCache = payload;
      return state;
    },
    submit: (state, _action: PayloadAction<CreateInvestmentForm>) => state,
    setProfitLabel: (state, { payload } : PayloadAction<string>) => {
      if (payload === 'fpm') {
        state.profitLabel = 'Associate Profit Take';
      } else {
        state.profitLabel = 'Customer Profit Take';
      }
      return state;
    },
  },
});

export const createInvestmentActions = createInvestmentSlice.actions;
export const createInvestmentReducer = createInvestmentSlice.reducer;
