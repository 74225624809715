import React from 'react';
import Card from 'common/ui/Card';
import { formatDateTime } from 'common/util/date';
import { formatCurrency } from 'common/util/currency';
import { InvestmentDetailTransaction } from '../investmentDetailPageTypes';

const ManagementFeeTable: React.FC<({
  managementFees: InvestmentDetailTransaction[]})> = (data) => (
    <Card title="Management Fees Collected">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.managementFees.map((item, index) => (
            <tr>
              <td>{formatDateTime(item.dateTime)}</td>
              <td>{formatCurrency(item.amount, 'two')}</td>
            </tr>
          ))}
        </tbody>

      </table>

    </Card>
  );

export default ManagementFeeTable;
